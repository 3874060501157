import { ALL, isMobileFilterPanelActive, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { Component } from "../../../common/defui/component"
import { isFallbackRoomlistActive } from "../../../common/featureFlagUtil"
import { printCatch } from "../../../common/promiseUtils"
import { dom, Fragment } from "../../../common/tsxrender/dom"
import { AdvancedSearchOptions, updateShowLocation } from "../../advancedSearchOptions"
import { loadRooms, RoomReload, setRoomAnimation } from "../../roomList"
import { ReactWrapper } from "../ReactWrapper"
import { FallbackRoomlistContainer } from "./fallbackRoomlistContainer"
import { MobileRoomlistContainer } from "./mobileRoomlistContainer"
import { fetchTopTags } from "./spaHelpers"
import type { ReactComponent } from "../ReactRegistry"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

const TOP_TAGS_COUNT = 20
// Filters associated with the Advanced Search Options. ASO should not collapse
// when only these state keys are changing.
const ADVANCED_SEARCH_FILTERS: (keyof IURLState)[] = ["keywords", "regions", "sort"]
const NON_ADVANCED_SEARCH_FILTERS = ALL.filter((key) => !ADVANCED_SEARCH_FILTERS.includes(key))

export interface MobileRoomlistRootProps {
    animateThumbnails: boolean
    showLocation: boolean
}

export class MobileRoomlistRoot extends Component<HTMLDivElement, MobileRoomlistRootProps> {
    private advancedSearch: AdvancedSearchOptions
    private activeTagHeader: ReactComponent
    private topTagsBanner: ReactComponent
    private fallbackContainer?: FallbackRoomlistContainer

    protected initUI(props: MobileRoomlistRootProps): void {
        if (isMobileFilterPanelActive()) {
            this.bindMobileRoomlistFilters(props)
        }
        this.advancedSearch = new AdvancedSearchOptions(true)
        this.element.appendChild(<Fragment>
            <ReactWrapper
                component="MobileGendersBanner"
                componentProps={{}}
            />
            <ReactWrapper
                component="MobileFollowingNav"
                componentProps={{}}
            />
            <MobileRoomlistContainer
                advancedSearchOptions={this.advancedSearch}
                animate={props.animateThumbnails}
                showLocation={props.showLocation}
            />
            <MobileRoomlistContainer
                advancedSearchOptions={this.advancedSearch}
                animate={props.animateThumbnails}
                showLocation={props.showLocation}
                isSecondary={true}
            />
            {isFallbackRoomlistActive() && <FallbackRoomlistContainer
                classRef={(c) => this.fallbackContainer = c}
                animate={props.animateThumbnails}
                showLocation={props.showLocation}
                mobile={true}
            />}
        </Fragment>)

        if (!isMobileFilterPanelActive()) {
            document.getElementById("mobile-header-advanced-options-container")!.prepend(<ReactWrapper
                component="ActiveTagHeader"
                componentProps={{
                    activeHashtag: UrlState.current.state.tags?.[0],
                    returnToTagsIndex: new URLSearchParams(window.location.search).get("ref") === "all",
                }}
                reactRef={(ref) => this.activeTagHeader = ref}
            />)

            const topTags = <ReactWrapper
                component="TopTagsBanner"
                componentProps={{
                    activeHashtag: UrlState.current.state.tags?.[0],
                    showType: UrlState.current.state.showType,
                    topTags: [],
                    onRender: () => {
                        // Avoid UI pop-in by only swapping out the placeholder after rendering completes
                        document.getElementById("mobile-hashtags-placeholder")?.replaceWith(topTags)
                    },
                }}
                reactRef={(ref) => this.topTagsBanner = ref}
            />
            this.updateTopTagsBanner(UrlState.current.state)
        }
        this.bindUrlStateListeners()
        // Poll to unblur room thumbnails after the age gate/entrance terms are closed.
        // To save bandwidth on mobile, the refresh interval clears itself after unblurring.
        RoomReload.scheduleImageRefresh(1)
    }

    private bindUrlStateListeners(): void {
        if (!isMobileFilterPanelActive()) {
            UrlState.current.listen(["genders", "showType", "tags", "sort"], (newState) => {
                this.activeTagHeader.update({
                    activeHashtag: newState.tags?.[0],
                    returnToTagsIndex: new URLSearchParams(window.location.search).get("ref") === "all",
                })
                this.updateTopTagsBanner(newState)
            }, this.element)
            // Ensure that Advanced Search Options are collapsed on history navigation, and on
            // any state change EXCEPT for ones that are initiated from within the ASO itself.
            // (And also sort, since changing sort doesn't cause a full nav in the control.)
            UrlState.current.listen(NON_ADVANCED_SEARCH_FILTERS, (state, prevState) => {
                // However, submitting the same search twice causes a setPartialState() call
                // with no actual changes, and we don't want to collapse the ASO in that case
                if (UrlState.getChangedKeys(state, prevState).length > 0) {
                    this.advancedSearch.openOrCloseMenu(false)
                }
            }, this.element)
            window.addEventListener("popstate", () => this.advancedSearch.openOrCloseMenu(false))
        }

        if (isFallbackRoomlistActive()) {
            UrlState.current.listen(ALL, () => {
                // Initially hide fallback roomlist on all state changes, if it needs to be shown
                // it will be triggered by the completion of the primary roomlist fetch
                this.fallbackContainer?.hideElement()
            }, this.element)
        }
    }

    private updateTopTagsBanner(state: IURLState): void {
        fetchTopTags(state.genders, TOP_TAGS_COUNT).then((topTags) => {
            this.topTagsBanner.update({
                activeHashtag: state.tags?.[0],
                showType: state.showType,
                topTags,
            })
        }).catch(printCatch)
    }

    private bindMobileRoomlistFilters(props: MobileRoomlistRootProps): void {
        document.querySelector("#mobile-filters-top-section")!.replaceWith(
            <ReactWrapper
                component="RoomlistFilters"
                componentProps={{
                    activeSection: "",
                    setThumbnailAnimations: setRoomAnimation,
                    showLocationRouter: updateShowLocation,
                    reloadRooms: loadRooms,
                }}
            />,
        )
    }
}
