import {
    getRoomlistSortCookieValue,
    isStateOnlyUsingSortSupportedKeys,
    PageType,
    ROOMLIST_SORT_COOKIE_NAME,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { MobileRoomlistRoot } from "../cb/components/roomlist/mobileRoomlistRoot"
import { bindAllDismissibleNotices } from "../cb/dismissibleNotice"
import { SpaMetaUpdate } from "../cb/components/head/spaMetaUpdate"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { isLanguageSubdomainActive } from "../common/featureFlagUtil"
import { addPageAction } from "../common/newrelic"
import { BaseEntryPoint, exportEntryPoint } from "./base"
import type { SortOption } from "@multimediallc/cb-roomlist-prefetch"


exportEntryPoint(class Page extends BaseEntryPoint {
    protected initMobile(): void {
        super.initMobile()
        bindAllDismissibleNotices()
        bindLogoClick()

        const rightNav = document.querySelector<HTMLDivElement>("div.right-nav")
        if (rightNav !== null) {
            const mobileSortContainer = document.createElement("div")
            rightNav.insertBefore(mobileSortContainer, rightNav.firstChild)

            const MobileSort = ReactComponentRegistry.get("MobileSort")
            const initialSortCookieValue = getInitialSortValue()
            // Sort choice gets saved to a cookie whenever changed, so if the URL doesn't
            // align with the most recent selection we need to update with a replaceState.
            // Replacing instead of pushing also skips pagination reset in setPartialState.
            if (UrlState.current.state.sort !== initialSortCookieValue) {
                UrlState.current.setPartialState({ sort: initialSortCookieValue }, true)
            }
            new MobileSort({}, mobileSortContainer)
        }

        new MobileRoomlistRoot(
            document.querySelector<HTMLDivElement>("div#base_content_block")!,
            {
                animateThumbnails: this.context.animateThumbnails,
                showLocation: this.context.showLocation,
            },
        )

        new SpaMetaUpdate()
    }
})

function getInitialSortValue(): SortOption | undefined {
    if (isStateOnlyUsingSortSupportedKeys(UrlState.current.state)) {
        if (UrlState.current.state.sort !== undefined) {
            setCookieWithExpiration(ROOMLIST_SORT_COOKIE_NAME, UrlState.current.state.sort, { days: 3 }, "/", isLanguageSubdomainActive())
            return UrlState.current.state.sort
        } else {
            return getRoomlistSortCookieValue()
        }
    }
    return undefined
}

function bindLogoClick() {
    const logoEl = document.querySelector<HTMLElement>("#mheader-logo, #whitelabel-header-logo")
    if (logoEl !== null) {
        logoEl.addEventListener("click", (event: MouseEvent) => {
            event.preventDefault()
            addPageAction("ChaturbateLogoClicked")
            // Clicking on the logo should restore most recently applied sort value
            const targetUrl = UrlState.current.convertStateToUrl({
                pageType: PageType.HOME,
                sort: getRoomlistSortCookieValue(),
            })
            UrlState.current.navigateTo(targetUrl)
        })
    }
}

