import {
    DEFAULT_PAGESIZE,
    getAPIParamsFromURLState,
    getCategorySlug,
    getPaginationAPIParams,
    HOMEPAGE_KEYS,
    isMobileFilterPanelActive,
    PageType,
    ROOMLIST_API_URL,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isFallbackRoomlistActive } from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { ReactWrapper } from "../ReactWrapper"
import { getRegionsUserPrefsFilters } from "./filters/filtersUtil"
import { PaginatedApiRoomList } from "./paginatedApiRoomList"
import { showFallbackRoomlist } from "./spaHelpers"
import type { AdvancedSearchOptions } from "../../advancedSearchOptions"
import type { ReactComponent } from "../ReactRegistry"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"

interface MobileRoomlistContainerProps {
    advancedSearchOptions: AdvancedSearchOptions
    animate: boolean
    showLocation: boolean
    isSecondary?: boolean
}

export class MobileRoomlistContainer extends HTMLComponent<HTMLDivElement, MobileRoomlistContainerProps> {
    private roomlist: PaginatedApiRoomList
    private pagination: ReactComponent
    private categoryHeader?: ReactComponent
    private searchMessage: ReactComponent
    private reloadMessage: ReactComponent
    private props: MobileRoomlistContainerProps

    protected createElement(props: MobileRoomlistContainerProps): HTMLDivElement {
        const pageParam = props.isSecondary === true ? "pageb" : "page"

        const onSearchOptionLinkClick = (ev: MouseEvent) => {
            ev.preventDefault()
            props.advancedSearchOptions.openOrCloseMenu(true)
            window.scrollTo({ top: -100, behavior: "smooth" })
        }

        return (
            <div className="roomlist-container" data-testid="room-list-container">
                <ReactWrapper
                    component="MobileCategoryHeader"
                    componentProps={{ categorySlug: props.isSecondary === true ? "hidden-cams" : getCategorySlug() }}
                    reactRef={(ref) => this.categoryHeader = ref}
                />
                <ReactWrapper
                    component="MobileSearchMessaging"
                    componentProps={{
                        keywords: UrlState.current.state.keywords,
                        numRooms: -1,  // Initial hidden state
                        onSearchOptionLinkClick: onSearchOptionLinkClick,
                    }}
                    reactRef={(ref) => this.searchMessage = ref}
                />
                <ReactWrapper
                    component="MobileRoomlistReloadMessage"
                    componentProps={{ isShown: false }}
                    reactRef={(ref) => this.reloadMessage = ref}
                />
                <PaginatedApiRoomList
                    classRef={(c) => this.roomlist = c}
                    apiUrl={ROOMLIST_API_URL}
                    pageSize={DEFAULT_PAGESIZE}
                    animate={props.animate}
                    showLocation={props.showLocation}
                />
                <ReactWrapper
                    component="MobilePagination"
                    componentProps={{
                        currPage: 1,
                        numPages: 0,
                        getHref: (page: number) => {
                            return UrlState.current.getURLForPartialState({ [pageParam]: page })
                        },
                        onPageChange: (page: number) => {
                            UrlState.current.setPartialState({ [pageParam]: page })
                            if (props.isSecondary === true) {
                                this.element.scrollIntoView()
                            } else {
                                window.scrollTo(0, 0)
                            }
                        },
                    }}
                    reactRef={(ref) => this.pagination = ref}
                />
            </div>
        )
    }

    protected initData(props: MobileRoomlistContainerProps): void {
        this.props = props
        UrlState.current.listen([...HOMEPAGE_KEYS, "pageType"], () => {
            if (!this.isActive()) {
                this.hideElement()
                return
            }
            this.loadRooms()
            this.categoryHeader?.update({ categorySlug: props.isSecondary === true ? "hidden-cams" : getCategorySlug() })
            this.showElement()
        }, this.element)

        if (props.advancedSearchOptions !== undefined && !isMobileFilterPanelActive()) {
            props.advancedSearchOptions.regionsChanged.listen(() => {
                if (this.isActive()) {
                    this.loadRooms()
                }
            })
        }

        if (this.isActive()) {
            // Prefetch is only supported for the primary container
            const prefetch = props.isSecondary === true ? undefined : window.prefetchPromise
            this.loadRooms(prefetch)
            delete window.prefetchPromise  // Ensure prefetch is only used once if present
            this.showElement()
        } else {
            this.hideElement()
        }
    }

    loadRooms(prefetchPromise?: Promise<string>): void {
        this.searchMessage.update({ numRooms: -1 })  // Hide search messaging until room fetch completes
        this.roomlist.fetchRooms(prefetchPromise, this.getAPIFilters()).then(({ matchedCount, page }) => {
            this.reloadMessage.update({ isShown: false })
            page = this.props.isSecondary === true ? UrlState.current.state.pageb : UrlState.current.state.page
            this.onRoomsLoaded(page ?? 1, matchedCount)
        }).catch((err) => {
            this.reloadMessage.update({ isShown: true })
            error("Failed to load roomlist page after retrying", err)
        })
    }

    private onRoomsLoaded(page: number, matchedCount: number): void {
        this.pagination.update({
            "currPage": page,
            "numPages": Math.max(Math.ceil(matchedCount / DEFAULT_PAGESIZE), 1),
        })
        this.searchMessage.update({
            numRooms: matchedCount,
            keywords: UrlState.current.state.keywords,
        })
        // If we didn't get any rooms but matchedCount > 0 then we must be past the end of the results,
        // so jump back to page 1 and try loading rooms again.
        const shouldResetToPageOne = page > 1 && matchedCount > 0 && this.roomlist.rooms.length === 0
        if (shouldResetToPageOne) {
            UrlState.current.setPartialState(this.props.isSecondary === true ? { pageb: 1 } : { page: 1 })
        }
        if (isFallbackRoomlistActive() && this.props.isSecondary !== true) {
            showFallbackRoomlist.fire(matchedCount)
        }
    }

    private getAPIFilters(): IRoomListAPIParams {
        const asoRegions = getRegionsUserPrefsFilters()
        const params = {
            ...asoRegions,
            ...getAPIParamsFromURLState(UrlState.current.state),
        }
        // Prevents filtering by private price on all of spy-on-cams
        if (UrlState.current.state.showType === ShowType.PRIVATE) {
            delete params.private_prices
        }
        if (this.props.isSecondary !== true) {
            return params
        }

        delete params.spy_show_prices
        return {
            ...params,
            [ShowType.PRIVATE]: false,
            [ShowType.HIDDEN]: true,
            ...getPaginationAPIParams(UrlState.current.state.pageb),
        }
    }

    private isActive(): boolean {
        return UrlState.current.state.pageType === PageType.HOME &&
            (this.props.isSecondary !== true || UrlState.current.state.showType === ShowType.PRIVATE)
    }
}
